import { SystemStyleObject } from '@chakra-ui/react'

const sizes: Record<string, SystemStyleObject> = {
  xs: {
    fontSize: { base: '1.125rem' },
    fontWeight: { base: 700 },
    lineHeight: { base: '1.350rem' },
  },
  sm: {
    fontSize: { base: '1.5rem' },
    fontWeight: { base: 700 },
    lineHeight: { base: '1.875rem' },
  },
  md: {
    fontSize: { base: '1.875rem' },
    fontWeight: { base: 700 },
    lineHeight: { base: '2rem' },
  },
  lg: {
    fontSize: { base: '2rem' },
    fontWeight: { base: 700 },
    lineHeight: { base: '2.25rem' },
  },
}

export const Heading = {
  sizes,
  variants: {},
  // The default size and variant values
  defaultProps: {
    size: 'lg',
    color: 'black',
  },
}
