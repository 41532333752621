import { Flex, Text } from '@chakra-ui/react'

const CenteredDotWithText = ({ text }: { text: string }) => {
  return (
    <Flex w="full" direction="row" columnGap={5} alignItems="center">
      <Flex w="10px" h="10px" minW="10px" borderRadius={'50%'} bgColor="green.300" />
      <Text size="lg" color="fakeblack">
        {text}
      </Text>
    </Flex>
  )
}

export default CenteredDotWithText
