import { SystemStyleObject } from '@chakra-ui/react'

const sizes: Record<string, SystemStyleObject> = {
  md: {
    h: '48px',
    padding: '14px 20px',
  },
}

const baseButton = {
  border: 0,
  outline: 0,
  width: 'fit-content',
  _focus: {
    outline: 0,
    boxShadow: 0,
  },
}

const primary: SystemStyleObject = {
  ...baseButton,
  bg: 'green.300',
  color: 'green.900',
  borderRadius: '2px',
}

export const Button = {
  sizes,
  variants: {
    primary,
  },
  defaultProps: {
    size: 'md',
    variant: '',
    colorScheme: '',
  },
}
