import { SystemStyleObject } from '@chakra-ui/react'

const sizes: Record<string, SystemStyleObject> = {
  sm: {
    fontSize: '0.75rem', //12px
    lineHeight: '0.975rem',
  },
  md: {
    fontSize: '0.875rem', //14px
    lineHeight: '1.4rem',
  },
  lg: {
    fontSize: '1rem', //16px
    lineHeight: '1.5rem',
  },
  xl: {
    fontSize: '1.125rem', //18px
    lineHeight: '1.575rem',
  },
  '2xl': {
    fontSize: '1.25rem', //20px
    lineHeight: '1.75rem',
  },
}

export const Text = {
  sizes,
  variants: {},
  // The default size and variant values
  defaultProps: {
    size: 'lg',
    color: 'black',
  },
}
