import { Flex, Heading, Divider } from '@chakra-ui/react'

const Banner = ({ text, bgColor, textColor }: { text: string; bgColor: string; textColor: string }) => {
  return (
    <Flex w="full" h="240px" bg={bgColor} alignItems="center" justifyContent="center" direction="column" rowGap={6}>
      <Heading size="lg" textTransform="uppercase" color={textColor} textAlign={{ base: 'center', lg: 'start' }}>
        {text}
      </Heading>
      <Divider w="50px" borderColor="red" borderWidth={'1px'} opacity={1} />
    </Flex>
  )
}

export default Banner
