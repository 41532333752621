import { Flex, Heading, Text, Button, Link } from '@chakra-ui/react'

import { CenteredDotWithText, GreenBloc } from '@components'

const Agri = () => {
  return (
    <Flex w="full" direction="column" py={{ base: 3, lg: 12 }} px={{ base: 0, lg: 36 }} bg="pink" rowGap={8}>
      <Heading size="xs" color="red" textAlign={{ base: 'center', lg: 'start' }}>
        Pour être éligible
      </Heading>
      <Flex direction="column" w="full" rowGap={8} px={{ base: 0, lg: 12 }}>
        <CenteredDotWithText text="Remplir les critères d’éligibilité du programme Avenir Lait d’Agrimousquetaires" />
        <CenteredDotWithText text="Avoir le statut de Jeune Agriculteur* depuis le 1er janvier 2023" />
        <CenteredDotWithText text="S’engager à réaliser un accompagnement technique** par un expert" />
      </Flex>
      <Flex w="full" direction="column">
        <Text color="fakeblack" size="sm">
          * avoir moins de 40 ans et réalisé une 1ère installation en lait à la Laiterie Saint Père ou à La Fruitière de
          Domessin, dont l’activité principale participe au maintien ou au développement de l’atelier laitier, et dont
          l’activité laitière correspond au moins à 50% du CA total de l’exploitation (accord préalable de la laiterie)
        </Text>
        <Text color="fakeblack" size="sm">
          ** pour une durée de 12 à 18 mois à compter de la 1ère année d’installation
        </Text>
      </Flex>
      <Flex direction={{ base: 'column', lg: 'row' }} w="full" columnGap={12} rowGap={6}>
        <GreenBloc
          iconName={'ForfaitAgri'}
          text={'Jusqu’à 20 000 € HT'}
          subTexts={['de soutien financier* pour réaliser des travaux de modernisation** de leur atelier laitier']}
          bolded={true}
        />
        <GreenBloc
          iconName={'PersonAgri'}
          text={'Jusqu’à 5 000 € HT'}
          subTexts={[
            'de soutien financier pour un accompagnement technique* par un expert sélectionné par les laiteries',
          ]}
          bolded={true}
        />
      </Flex>
      <Flex w="full" direction="column">
        <Text color="fakeblack" size="sm">
          *l’Agriculteur Partenaire devra fournir des attestations de fin de travaux et de réalisation de
          l’accompagnement technique
        </Text>
        <Text color="fakeblack" size="sm">
          **par ordre de priorité : travaux d&apos;accès à l&apos;élevage, local stockage de lait, matériel de traite,
          aménagement et/ou amélioration des pâturages et/ou bien-être animal
        </Text>
      </Flex>
      <Heading size="xs" color="red" textAlign={{ base: 'center', lg: 'start' }}>
        Documents à fournir
      </Heading>
      <Flex direction="column" w="full" rowGap={8} px={{ base: 0, lg: 12 }}>
        <CenteredDotWithText text="Les factures des travaux effectués pendant la période du 1er janvier au 31 décembre 2024 et, le cas échéant l’attestation de fin de travaux complétée par la laiterie" />
        <Text size="lg">OU</Text>
        <CenteredDotWithText text="Les devis des travaux ou une estimation pour la période du 1er janvier au 31 décembre 2024" />
      </Flex>
      <Link href="#form" alignSelf="center">
        <Button variant="primary">
          <Text size="md" fontWeight="400">
            Envoyer ma demande de contact
          </Text>
        </Button>
      </Link>
    </Flex>
  )
}

export default Agri
