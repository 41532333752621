import { HamburgerIcon } from '@chakra-ui/icons'
import {
  Flex,
  Heading,
  Button,
  Text,
  useBreakpointValue,
  Link,
  Image,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'

import { Icon } from '@miimosa/design-system'

const FakeHeaderHero = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })

  const { isOpen, onClose, onToggle } = useDisclosure()

  return (
    <Flex w="full" direction="row" h="full">
      <Flex w="full" h="auto" direction="column">
        {isMobile && (
          <Flex position="relative" w="100%" h="100%">
            <Image
              alt=""
              src={'/images/cow.jpg'}
              style={{ opacity: '0.4', zIndex: 1, inset: '0px' }}
              w="100%"
              h="100%"
              objectFit="cover"
              position="absolute"
            />

            <Flex w="full" h="full" direction="column" bg="green.900" position="relative" top={0}>
              <Flex
                w="full"
                alignItems="center"
                zIndex={99}
                bg="green.900"
                position="fixed"
                h="100px"
                direction="row"
                justifyContent="space-around"
              >
                <Icon name={'LogoAgri'} size="xl" h="auto" />
                <HamburgerIcon onClick={onToggle} color="white" boxSize={'30px'} />
              </Flex>
              <Flex
                direction="column"
                w="full"
                top={'100px'}
                position="relative"
                px={{ base: 8, lg: 16 }}
                pt={4}
                pb={40}
                rowGap={14}
              >
                <Heading size="lg" color="white" as="h1" zIndex={2}>
                  Agrimousquetaires agit avec les Agriculteurs Partenaires du Groupement Les Mousquetaires pour soutenir
                  la transition agricole
                </Heading>
                <Text size="xl" color="white" zIndex={2}>
                  Dans le cadre de son “<strong>Programme Avenir Lait 2024</strong>”, l&apos;association
                  Agrimousquetaires soutient des projets favorisant l’attractivité du métier d’agriculteur ainsi que la
                  transition énergétique et environnementale.
                </Text>
                <Link href="#form" zIndex={2}>
                  <Button variant="primary">Formulaire de contact</Button>
                </Link>
              </Flex>
            </Flex>
            {isOpen && (
              <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="full">
                <DrawerOverlay />
                <DrawerContent w="full" h="full" bg="green.900">
                  <DrawerCloseButton color="white" />
                  <DrawerBody>
                    <Flex w="full" direction="column" rowGap={3} py={4}>
                      <Link href="#form" color="white" onClick={onClose}>
                        <Text size="xl" w="full">
                          Formulaire de contact
                        </Text>
                      </Link>
                      <Link href="#ambition" color="white" onClick={onClose}>
                        <Text size="xl" w="full">
                          L’ambition du Programme Avenir Lait
                        </Text>
                      </Link>
                      <Link href="#actions" color="white" onClick={onClose}>
                        <Text size="xl" w="full">
                          Les appels à projets
                        </Text>
                      </Link>
                      <Link href="#faq" color="white" onClick={onClose}>
                        <Text size="xl" w="full">
                          FAQ
                        </Text>
                      </Link>
                    </Flex>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
            )}
          </Flex>
        )}
        {!isMobile && (
          <Flex
            w="full"
            h="full"
            direction="column"
            rowGap={14}
            pb={40}
            px={16}
            bg="green.900"
            position="relative"
            top={0}
          >
            <Flex w="full" alignItems="center" pt={4}>
              <Icon name={'LogoAgri'} size="xl" h="auto" />
            </Flex>
            <Heading size="lg" color="white" as="h1">
              Agrimousquetaires agit avec les Agriculteurs Partenaires du Groupement Les Mousquetaires pour soutenir la
              transition agricole
            </Heading>
            <Text size="xl" color="white">
              Dans le cadre de son “<strong>Programme Avenir Lait 2024</strong>”, l&apos;association Agrimousquetaires
              soutient des projets favorisant l’attractivité du métier d’agriculteur ainsi que la transition énergétique
              et environnementale.
            </Text>
            <Link href="#form">
              <Button variant="primary">Formulaire de contact</Button>
            </Link>
          </Flex>
        )}
      </Flex>
      {!isMobile && (
        <Flex
          w="full"
          direction="column"
          justifyContent="flex-start"
          backgroundImage={'images/cow.jpg'}
          backgroundSize="cover"
          backgroundPosition="center"
        >
          <Flex w="full" direction="row" alignItems="center" justifyContent="space-around" pt={4}>
            <Link href="#form">
              <Button variant="primary">
                <Text size="md">Formulaire de contact</Text>
              </Button>
            </Link>
            <Link href="#ambition">
              <Text size="md" color="green.900">
                L’ambition du Programme Avenir Lait
              </Text>
            </Link>
            <Link href="#actions">
              <Text size="md" color="green.900">
                Les appels à projets
              </Text>
            </Link>
            <Link href="#faq">
              <Text size="md" color="green.900">
                FAQ
              </Text>
            </Link>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

export default FakeHeaderHero
