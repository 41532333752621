import { Flex, Heading, Text, Button, Link } from '@chakra-ui/react'

import { CenteredDotWithText, GreenBloc } from '@components'

const Energy = () => {
  return (
    <Flex w="full" direction="column" py={{ base: 3, lg: 12 }} px={{ base: 0, lg: 36 }} bg="pink" rowGap={8}>
      <Heading size="xs" color="red" textAlign={{ base: 'center', lg: 'start' }}>
        Pour être éligible
      </Heading>
      <Flex direction="column" w="full" rowGap={8} px={{ base: 0, lg: 12 }}>
        <CenteredDotWithText text="Remplir les critères d’éligibilité du programme Avenir Lait d’Agrimousquetaires " />
      </Flex>
      <Flex direction={{ base: 'column', lg: 'row' }} w="full" columnGap={12} rowGap={6}>
        <GreenBloc
          iconName={'TankAgri'}
          text={'Tank à lait à économie d’énergie*'}
          subTexts={[
            'Prise en charge de l’écart de prix avec un tank à lait classique (dans la limite d’un plafond de 20 000 € HT)',
          ]}
          bolded={true}
        />
        <GreenBloc
          iconName={'ColdAgri'}
          text={'Pré-refroidisseur'}
          subTexts={['Subvention à hauteur 20% prix (dans la limite d’un plafond 2 000 € HT)']}
          bolded={true}
        />
        <GreenBloc
          iconName={'BurnAgri'}
          text={'Récupérateur de chaleur'}
          subTexts={['Subvention à hauteur 20% prix (dans la limite d’un plafond 2 000 € HT)']}
          bolded={true}
        />
      </Flex>
      <Flex w="full" direction="column">
        <Text color="fakeblack" size="sm">
          *Pour l’acquisition d’un tank à lait à réduction d’énergie : la priorité sera donnée aux demandes de
          financement des Jeunes Agriculteurs Partenaires et pour les autres projets de financement l’Agriculteur
          Partenaire devra présenter sa demande de financement aux laiteries.
        </Text>
      </Flex>
      <Heading size="xs" color="red" textAlign={{ base: 'center', lg: 'start' }}>
        Documents à fournir
      </Heading>
      <Flex direction="column" w="full" rowGap={8} px={{ base: 0, lg: 12 }}>
        <CenteredDotWithText text="Factures des travaux effectués pendant la période du 01/01/2024 au 31/12/2024 et, le cas échéant l’attestation équipements de réduction énergie complétée par la laiterie" />
        <CenteredDotWithText text="Devis des travaux à venir  (si disponibles), pour la période du 01/01/2024 au 31/12/2024 ou une estimation à venir" />
      </Flex>
      <Link href="#form" alignSelf="center">
        <Button variant="primary">
          <Text size="md" fontWeight="400">
            Envoyer ma demande de contact
          </Text>
        </Button>
      </Link>
    </Flex>
  )
}

export default Energy
