// import '../styles/globals.css'
// import '../styles/fonts.css'

import { ChakraProvider } from '@chakra-ui/react'
import * as Sentry from '@sentry/nextjs'
import { type NextPageContext } from 'next'
import type { AppProps } from 'next/app'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

import { getDomainFromPath, getTagID } from '@miimosa/common'

import { theme } from '../themes'

import { Layout } from '@components'

MyApp.getInitialProps = async ({ ctx }: { ctx: NextPageContext }) => {
  // This is used at build time so we cannot rely on the host name here
  // Instead we use the `pathname` which is the path that is generated
  // (e-g / or /_sites/innocent)
  const domainName = getDomainFromPath(ctx.pathname ?? '')
  return { domainName }
}

function MyApp({ domainName, Component, pageProps }: AppProps & any) {
  useEffect(() => {
    const gtmId = getTagID(domainName)
    if (gtmId) {
      TagManager.initialize({ gtmId })
    }
  }, [domainName])

  return (
    <Sentry.ErrorBoundary showDialog={false}>
      <ChakraProvider theme={theme}>
        <Layout>{<Component {...pageProps} />}</Layout>
      </ChakraProvider>
    </Sentry.ErrorBoundary>
  )
}

export default MyApp
