import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Dispatch, SetStateAction, useContext } from 'react'

import { Banner, Holiday, Agri, Energy } from '@components'
import { TabContext } from '@components/TabContext'

const DesktopTabs = ({
  selectedTab,
  setSelectedTab,
}: {
  selectedTab: string
  setSelectedTab: Dispatch<SetStateAction<string>>
}) => {
  const displaySelectedTab = {
    holiday: <Holiday />,
    agri: <Agri />,
    energy: <Energy />,
  }
  return (
    <Flex direction="column" w="full">
      <Flex direction="row" px={32} h="130px">
        <Flex
          w="full"
          h="full"
          alignItems="center"
          justifyContent="center"
          borderBottom={selectedTab == 'holiday' ? 'solid 3px red' : ''}
          onClick={() => setSelectedTab('holiday')}
          _hover={{ cursor: 'pointer' }}
        >
          <Text
            size="2xl"
            align="center"
            color={selectedTab == 'holiday' ? 'green.300' : 'fakeblack'}
            whiteSpace="pre-wrap"
          >
            {'Aide au remplacement\npour congés vacances'}
          </Text>
        </Flex>
        <Flex
          w="full"
          h="full"
          alignItems="center"
          justifyContent="center"
          onClick={() => setSelectedTab('agri')}
          borderBottom={selectedTab == 'agri' ? 'solid 3px red' : ''}
          _hover={{ cursor: 'pointer' }}
        >
          <Text
            size="2xl"
            align="center"
            color={selectedTab == 'agri' ? 'green.300' : 'fakeblack'}
            whiteSpace="pre-wrap"
          >
            {'Pack à l’installation des\nJeunes Agriculteurs'}
          </Text>
        </Flex>
        <Flex
          w="full"
          h="full"
          alignItems="center"
          justifyContent="center"
          onClick={() => setSelectedTab('energy')}
          borderBottom={selectedTab == 'energy' ? 'solid 3px red' : ''}
          _hover={{ cursor: 'pointer' }}
        >
          <Text
            size="2xl"
            align="center"
            color={selectedTab == 'energy' ? 'green.300' : 'fakeblack'}
            whiteSpace="pre-wrap"
          >
            {'Aide aux équipements à\nréduction d’énergie'}
          </Text>
        </Flex>
      </Flex>
      <Flex px={32}>{displaySelectedTab[selectedTab as keyof typeof displaySelectedTab]}</Flex>
    </Flex>
  )
}

const MobileTabs = () => {
  const displaySelectedTab = {
    holiday: <Holiday />,
    agri: <Agri />,
    energy: <Energy />,
  }

  const Item = ({ selectedTab, isExpanded, title }: { selectedTab: string; isExpanded: boolean; title: string }) => {
    return (
      <Flex
        w="full"
        bg="pink"
        direction="column"
        borderBottom="solid 3px red"
        justifyContent="space-around"
        alignItems="center"
      >
        <AccordionButton justifyContent="space-between" w="full" flexDirection="row" h="134px" columnGap={3}>
          <Text size="2xl" align="center" color={'green.300'} whiteSpace="pre-wrap">
            {title}
          </Text>
          {isExpanded ? <MinusIcon fontSize="24px" color={'red'} /> : <AddIcon fontSize="24px" color={'red'} />}
        </AccordionButton>
        <AccordionPanel>{displaySelectedTab[selectedTab as keyof typeof displaySelectedTab]}</AccordionPanel>
      </Flex>
    )
  }

  return (
    <Flex direction="column" w="full" px={6}>
      <Accordion
        defaultIndex={[0]}
        allowMultiple={true}
        display="flex"
        w="full"
        h="full"
        alignItems="flex-start"
        justifyContent="center"
        flexDirection={'column'}
        columnGap={10}
        rowGap={10}
        flexWrap="wrap"
      >
        <AccordionItem w="full" h="full">
          {({ isExpanded }) => (
            <Item isExpanded={isExpanded} selectedTab={'holiday'} title={'Aide au remplacement pour congés vacances'} />
          )}
        </AccordionItem>
        <AccordionItem w="full" h="full">
          {({ isExpanded }) => (
            <Item
              isExpanded={isExpanded}
              selectedTab={'agri'}
              title={'Pack à l’installation des Jeunes Agriculteurs'}
            />
          )}
        </AccordionItem>
        <AccordionItem w="full" h="full">
          {({ isExpanded }) => (
            <Item isExpanded={isExpanded} selectedTab={'energy'} title={'Aide aux équipements à réduction d’énergie'} />
          )}
        </AccordionItem>
      </Accordion>
    </Flex>
  )
}

const ProjectCall = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })
  const { selectedTab, setSelectedTab } = useContext(TabContext)

  return (
    <Flex direction="column" w="full" rowGap={6} my={10} id="actions">
      <Banner text={'Les appels à projets'} bgColor={'pink'} textColor={'red'} />
      {isMobile ? <MobileTabs /> : <DesktopTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />}
    </Flex>
  )
}

export default ProjectCall
