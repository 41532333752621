import Head from 'next/head'
import { ReactElement } from 'react'

import { Footer } from '@components'
import { s3ImageURL } from '@miimosa/common'

const Layout = ({ children }: { children: ReactElement }) => {
  return (
    <>
      <Head>
        <meta
          name="description"
          content="A travers le programme Avenir Lait, l’association Agrimousquetaires soutient les Agriculteurs Partenaires parle financement de leurs projets de transition agricole et environnementale."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="icon" type="image/png" href="/favicon.png" sizes="64x64" />
        <meta
          name="title"
          content="Agrimousquetaires agit avec les Agriculteurs Partenaires du Groupement Les Mousquetaires pour soutenir la
          transition agricole"
        />
        <title>
          Agrimousquetaires agit avec les Agriculteurs Partenaires du Groupement Les Mousquetaires pour soutenir la
          transition agricole
        </title>
        <meta
          property="og:title"
          content="Agrimousquetaires agit avec les Agriculteurs Partenaires du Groupement Les Mousquetaires pour soutenir la
          transition agricole"
          key="title"
        />
        <meta
          property="og:description"
          content="A travers le programme Avenir Lait, l’association Agrimousquetaires soutient les Agriculteurs Partenaires parle financement de leurs projets de transition agricole et environnementale."
          key="description"
        />
        <meta property="og:image" content={s3ImageURL('cow.jpg')} key="image" />
      </Head>
      {/* <Header /> */}
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
