import { Flex, Heading, Text, Button, Link } from '@chakra-ui/react'

import { CenteredDotWithText, GreenBloc } from '@components'

const Holiday = () => {
  return (
    <Flex w="full" direction="column" py={{ base: 3, lg: 12 }} px={{ base: 0, lg: 36 }} bg="pink" rowGap={8}>
      <Heading size="xs" color="red" textAlign={{ base: 'center', lg: 'start' }}>
        Pour être éligible
      </Heading>
      <Flex direction="column" w="full" rowGap={8} px={{ base: 0, lg: 12 }}>
        <CenteredDotWithText text="Remplir les critères d’éligibilité du Programme Avenir Lait d’Agrimousquetaires" />
        <CenteredDotWithText text="Être adhérent au service de remplacement de son département" />
      </Flex>
      <Flex direction={{ base: 'column', lg: 'row' }} w="full" columnGap={12} rowGap={6}>
        <GreenBloc
          iconName={'ForfaitAgri'}
          text={'Forfait* par Agriculteur Partenaire'}
          subTexts={['6 jours / semaine **', '1 jour dimanche ou férié ***']}
        />
        <GreenBloc
          iconName={'PersonAgri'}
          text={'Forfait* par Jeune Agriculteur Partenaire'}
          subTexts={['7 jours / semaine **', '2 jours dimanche ou férié ***']}
        />
      </Flex>
      <Flex w="full" direction="column">
        <Text color="fakeblack" size="sm">
          *Forfait participatif en complément du crédit d’impôt pour chaque associé, dans la limite de 4 chefs
          exploitants par exploitation
        </Text>
        <Text color="fakeblack" size="sm">
          ** le nombre de jours / semaine est attribué proportionnellement en fonction du nombre d’associé/Agriculteur
          Partenaire
        </Text>
        <Text color="fakeblack" size="sm">
          *** 1 jour de congé = min 5h de remplacement à hauteur de 60 €/ jour (du lundi au samedi) et 90 €/ jour pour
          le dimanche et les jours fériés
        </Text>
      </Flex>
      <Heading size="xs" color="red" textAlign={{ base: 'center', lg: 'start' }}>
        Documents à fournir
      </Heading>
      <Flex direction="column" w="full" rowGap={8} px={{ base: 0, lg: 12 }}>
        <CenteredDotWithText text="Les factures de remplacement pour congés vacances (avec le nom du bénéficiaire), pour la période du 01/01/2024 au 31/12/2024" />
        <CenteredDotWithText text="Attestation adhésion au service remplacement" />
        <CenteredDotWithText text="Si Jeune Agriculteur (JA) : Attestation JA congés vacances complétée par la laiterie" />
      </Flex>
      <Link href="#form" alignSelf="center">
        <Button variant="primary">
          <Text size="md" fontWeight="400">
            Envoyer ma demande de contact
          </Text>
        </Button>
      </Link>
    </Flex>
  )
}

export default Holiday
